import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const ArthroseDuPouce = () => (
  <Layout
    title="Arthrose du pouce (rhizarthrose) : Diagnostic et traitement"
    description="L'Arthrose du pouce (rhizarthrose), est très fréquente, touchant surtout la femme et débutant le plus souvent vers l'âge de 50 ans."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Arthrose du pouce (rhizarthrose)" />
        <MainPart title="Qu'est-ce que l'arthrose du pouce ?">
          <Typography weight="bold">
            La rhizarthrose ou arthrose du pouce
            {' '}
            <Typography variant="span">
              est le plus souvent liée à la destruction progressive du cartilage du trapèze
              et de la base du premier métacarpe. Elle est très fréquente, touchant surtout
              la femme et débutant le plus souvent vers l&apos;âge de 50 ans.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Comment est constitué le pouce ?">
          <Typography>
            Le trapèze fait partie des os de la 2ème rangée du poignet.
            Il s&apos;articule en haut avec le scaphoïde et en bas avec le 1er métacarpe (Figure 1).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure1-arthrosedupouce.jpg"
            caption="Figure 1. Rapports anatomiques du trapèze."
            currentOpTitle="Arthrose du pouce"
          />
        </MainPart>
        <MainPart title="Quelles sont les causes possibles de l'arthrose du pouce ?">
          <Typography>
            Le plus souvent héréditaire, la rhizarthrose fait suite à des
            mouvements répétitifs du pouce
            responsables d&apos;une usure mécanique du cartilage. Certains
            cas peuvent survenir dans les
            suites d&apos;un traumatisme ou d&apos;une fracture du pouce.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer l'arthrose du pouce ?">
          <Typography>
            Le diagnostic de rhizarthrose est clinique. Les principaux symptômes sont :
          </Typography>
          <List>
            <List.Item>
              <Typography>
                Douleurs : situées à la racine du pouce, elles évoluent le plus souvent
                par crises. Les douleurs sont accentuées à la pression de la racine du
                pouce et lors des mouvements.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Diminution de mobilité : responsable d&apos;un enraidissement progressif du
                pouce avec difficulté d&apos;ouverture de la première
                commissure (écarter le pouce de l&apos;index)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>Perte de force de la pince pouce-index</Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Déformation inesthétique du pouce en Z ou M : observée dans les formes
                avancées de rhizarthrose (Figure 2).
              </Typography>
            </List.Item>
          </List>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure2-arthrosedupouce.jpg"
            caption="Figure 2. Déformation évoluée en M des deux pouces (pouce adductus)"
            currentOpTitle="Arthrose du pouce"
          />
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer l'arthrose du pouce ?">
          <Typography>
            La radiographie permet de faire le diagnostic de
            {' '}
            <a className="underline text-link hover:text-primary" href="https://www.passeportsante.net/fr/Maux/Problemes/Fiche.aspx?doc=rhizarthrose" target="_blank" rel="noreferrer">rhizarthrose</a>
            , d&apos;évaluer la hauteur
            du trapèze et de visualiser les déformations de la colonne du pouce. Cet examen
            est indispensable pour le choix du traitement chirurgical.
          </Typography>
          <Figure
            maxWidth="max-w-[175px]"
            imageName="figure3-arthrosedupouce.jpg"
            caption="Figure 3. Rhizarthrose évoluée visualisée sur les clichés radiographiques"
            currentOpTitle="Arthrose du pouce"
          />
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux pour l'arthrose du pouce (rhizarthrose) ?">
          <Typography>
            Le traitement
            {' '}
            <Typography variant="span" weight="bold">
              médical
            </Typography>
            {' '}
            doit être envisagé dans un premier temps :
          </Typography>
          <List>
            <List.Item>
              <Typography>Antalgiques et anti-inflammatoires</Typography>
            </List.Item>
            <List.Item>
              <Typography>Attelle de repos à porter la nuit</Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Infiltration : dont la répétition peut détériorer la peau,
                les tendons et les structures capsulo-ligamentaires du pouce
              </Typography>
            </List.Item>
          </List>
          <Typography>
            Le traitement
            {' '}
            <Typography variant="span" weight="bold">
              chirurgical
            </Typography>
            {' '}
            est indiqué en cas d&apos;échec du traitement médical bien
            conduit pendant au moins 6 mois.
            L&apos;intervention est le plus souvent réalisée sous anesthésie
            loco-régionale (seul le bras est endormi), en ambulatoire (pas d&apos;hospitalisation).
          </Typography>
          <Typography>
            Le type de chirurgie dépend du type d&apos;activité du patient
            (travail manuel ou non) et de l&apos;importance de l&apos;arthrose.
            Ces interventions ne redonnent jamais un pouce normal et
            ne permettent pas de récupérer la force de serrage complèt
            malgré une rééducation souvent longue.
          </Typography>
          <Typography weight="bold">1. Arthroscopie du pouce :</Typography>
          <Typography>
            Dans les stades précoces de rhizarthrose, l&apos;articulation peut être nettoyée sous
            arthroscopie. Deux petites incisions de quelques millimètres sont réalisées à la
            racine du pouce afin d&apos;introduire l&apos;optique et des
            instruments miniaturisés pour
            nettoyer l&apos;articulation et réséquer les becs osseux douloureux. Cette
            intervention a pour avantage d&apos;être peu invasive avec une récupération
            rapide et des séquelles esthétiques minimes. Elle peut retarder une intervention
            lourde (prothèse trapézo-métacarpienne ou trapézectomie) de plusieurs années.
          </Typography>
          <Typography weight="bold">2. Prothèse trapézo-métacarpienne :</Typography>
          <Typography>
            Il s&apos;agit d&apos;une prothèse dont le fonctionnement est très proche
            de la prothèse de hanche. Une cupule est mise en place dans le trapèze.
            La tige, introduite dans le métacarpe s&apos;articule, par l&apos;intermédiaire
            d&apos;un col, avec la cupule (Figure 4).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure4-arthrosedupouce.jpg"
            caption="Figure 4. Prothèse trapézo-métacarpienne et ses différents constituants"
            currentOpTitle="Arthrose du pouce"
          />
          <Typography>
            La prothèse a comme principal avantage de rétablir la longueur de la colonne
            du pouce et donc la force. La mobilité et la fonction sont proches
            de la normale (Figure 5).
          </Typography>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure5-arthrosedupouce.jpg"
            caption="Figure 5. Aspect radiographique après mise en place d&apos;une prothèse trapézo-métacarpienne"
            currentOpTitle="Arthrose du pouce"
          />
          <Typography>
            Les principaux risques sont : la luxation et l&apos;usure. En cas
            d&apos;échec ou de complications, l&apos;ablation de la prothèse
            associée à une trapézectomie reste possible.
          </Typography>
          <Typography weight="bold">3. la trapézectomie :</Typography>
          <Typography>
            Elle consiste à retirer complètement le trapèze (Figure 6). Elle est indiquée
            en cas d&apos;arthrose péri-trapézienne (arthrose au-dessus et en dessous du trapèze)
            et/ou chez les patients manuels (très actifs ou travailleurs manuels).
            L&apos;utilisation d&apos;un tendon prélevé localement (ligamentoplastie) est le
            plus souvent nécessaire afin de stabiliser la colonne du pouce et ainsi
            limiter son raccourcissement. Il s&apos;agit d&apos;une solution radicale et
            définitive dans le traitement de la rhizarthrose. Le résultat final
            est obtenu après 6 mois et sera stable dans le temps. La force de
            la pince pouce-index est en général diminuée.
          </Typography>
          <Figure
            maxWidth="max-w-[250px]"
            imageName="figure6-arthrosedupouce.jpg"
            caption="Figure 6. Aspect radiographique après une trapézectomie"
            currentOpTitle="Arthrose du pouce"
          />
          <Typography weight="bold">4. L&apos;arthrodèse (fusion) trapézo-métacarpienne :</Typography>
          <Typography>
            exceptionnellement indiquée chez les jeunes travailleurs manuels en cas d&apos;arthrose
            trapézo-métacarpienne isolée. Elle est le plus souvent responsable de raideur de
            la colonne du pouce et peut également provoquer des douleurs voire une arthrose
            de l&apos;articulation métacarpo-phalangienne du pouce.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les suites post-opératoires d'une opération chirurgicale de l'arthrose du pouce ?">
          <div className="flex flex-col">
            <Typography>
              La durée d&apos;immobilisation est variable selon le type de chirurgie.
            </Typography>
            <Typography>
              En cas de nettoyage sous arthroscopie, l&apos;immobilisation est
              uniquement à visée antalgique pendant une dizaine de jours.
            </Typography>
            <Typography>
              En cas de prothèse trapézo-métacarpienne, une attelle est portée 15 jours puis
              15 jours la nuit. En cas de trapézectomie,
              l&apos;attelle est portée pendant 6 semaines.
            </Typography>
            <Typography>
              Des séances de rééducation sont souvent indispensables
              à l&apos;ablation de l&apos;attelle
              afin de récupérer une bonne mobilité du pouce (Figure 7).
            </Typography>
          </div>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure7-arthrosedupouce.jpg"
            caption="Figure 7. Aspect post-opératoire à 6 semaines d&apos;une prothèse trapézo-métacarpienne du pouce gauche"
            currentOpTitle="Arthrose du pouce"
          />
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour de l'arthrose du pouce ?">
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Fourmillements du pouce par irritation des branches cutanées du nerf radial
                qui disparaissent en quelques jours à quelques semaines. La section
                d&apos;une branche nerveuse est exceptionnelle.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Douleurs : pouvant durer pendant 6 mois notamment en cas de trapézectomie.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Complications spécifiques de la prothèse
                (10% des cas): usure, luxation et descellement.
                La survenue d&apos;une complication aboutit en général à une ablation de la
                prothèse associée à une trapézectomie.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default ArthroseDuPouce;
